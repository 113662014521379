import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <article className="mw7 lh-copy center">
      <h1 className="tc">About Gabrielle Moncrease</h1>
      <div className="center w-50">
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(
                relativePath: { eq: "Gabrielle_profile-resized.png" }
              ) {
                childImageSharp {
                  fixed(height: 407, fit: CONTAIN) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data => (
            <Img
              className="center"
              imgStyle={{
                height: "100%",
                backgroundSize: "contain",
                objectFit: "contain",
              }}
              fixed={data.placeholderImage.childImageSharp.fixed}
            />
          )}
        />
      </div>
      <p>
        Gabrielle Moncrease, also known by the performing name G’Bell, a mother
        of two sons, spent her early childhood in Spokane, Washington, then
        later made Las Vegas her home and has been there now for over 20 years.
        Her first passion is writing plays. It allows her the opportunity to let
        people get a visual representation of what they are doing and how it
        affects other people’s lives.
      </p>
      <p>
        Her first chance to write for publication was in the early eighties for
        Hi Records, in Las Vegas. She had a column in a paper named “Gabby’s
        Corner.” It was an opportunity to take fragile, delicate matters and
        bring them out in such a way that people could respond emotionally.
        Since then, she has written two articles for the local Las Vegas
        magazine “Determined GrandMothers.” These articles, written between 2016
        and 2017 with other published authors, were addressing situations that
        teens are facing today, such as bullying and the occult.
      </p>
      <p>
        G’Bell’s first book, titled{" "}
        <em className="i">How I Survived My Son’s Suicide</em>, was inspired by
        the passing of her youngest son. The book, initially intended for her
        healing, ends up aiding the healing of her family, friends, her son’s
        fans, and anyone that was affected by suicide in some way. Regarding the
        book, she states, “I didn’t choose this project; it chose me.”
      </p>
      <section>
        <p>
          Gabrielle is in training to become more proactive in suicide
          prevention. It is her goal to bring healing and wholeness to all those
          who have lost hope.
        </p>
      </section>
    </article>
  </Layout>
)

export default AboutPage
